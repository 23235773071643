// TYPOGRAPHY
@mixin h1 {
  font-size: var(--font-size-h1);
  font-weight: var(--font-weight-h1);
  line-height: var(--line-height-h1);
  letter-spacing: var(--letter-spacing);
}
@mixin h2 {
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-h2);
  line-height: var(--line-height-h2);
  letter-spacing: var(--letter-spacing);
}

@mixin h3 {
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight-h3);
  line-height: var(--line-height-h3);
  letter-spacing: var(--letter-spacing);
}
@mixin h4 {
  font-size: var(--font-size-h4);
  font-weight: var(--font-weight-h4);
  line-height: var(--line-height-h4);
  letter-spacing: var(--letter-spacing);
}
@mixin h5 {
  font-size: var(--font-size-h5);
  font-weight: var(--font-weight-h5);
  line-height: var(--line-height-h5);
  letter-spacing: var(--letter-spacing);
}

@mixin h6 {
  font-size: var(--font-size-h6);
  font-weight: var(--font-weight-h6);
  line-height: var(--line-height-h6);
  letter-spacing: var(--letter-spacing);
}

@mixin bodyRegular {
  font-size: var(--font-size-body-regular);
  font-weight: var(--font-weight-body-regular);
  line-height: var(--line-height-body-regular);
  white-space: pre-wrap;
  letter-spacing: var(--letter-spacing);
}

@mixin bodySmall {
  font-size: var(--font-size-body-small);
  font-weight: var(--font-weight-body-small);
  line-height: var(--line-height-body-small);
  white-space: pre-wrap;
  letter-spacing: var(--letter-spacing);
}

@mixin bodyLarge {
  font-size: var(--font-size-body-large);
  font-weight: var(--font-weight-body-large);
  line-height: var(--line-height-body-large);
  white-space: pre-wrap;
  letter-spacing: var(--letter-spacing);
}

@mixin pageTitleSmall {
  font-size: var(--font-size-page-title-small);
  font-weight: var(--font-weight-page-title-small);
  line-height: var(--line-height-page-title-small);
  letter-spacing: var(--letter-spacing);
}

.h1 {
  @include h1;
}

.h2 {
  @include h2;
}

.h3 {
  @include h3;
}

.h4 {
  @include h4;
}

.h5 {
  @include h5;
}

.h6 {
  @include h6;
}

.bodyRegular {
  @include bodyRegular();
}

.bodyLarge {
  @include bodyLarge();
}

.pageTitleSmall {
  @include pageTitleSmall();
}

// RESPONSIVE
$SMALL: 0px;
$MEDIUM: 734px;
$LARGE: 1068px;
$X_LARGE: 1439px;

/*
    Responsive style example usage (mobile first):
    {className} {
        ...mobileStyles
        @include responsive($LARGE) {
            ...largeStyles
        }
        ...etc
    }
*/
@mixin responsive($media) {
  @if $media == $SMALL {
    @media (min-width: $SMALL) {
      @content;
    }
  } @else if $media == $MEDIUM {
    @media (min-width: $MEDIUM) {
      @content;
    }
  } @else if $media == $LARGE {
    @media (min-width: $LARGE) {
      @content;
    }
  } @else if $media == $X_LARGE {
    @media (min-width: $X_LARGE) {
      @content;
    }
  }
}

.onlyOnSmall {
  display: flex;
  @include responsive($MEDIUM) {
    display: none;
  }
}

.onlyOnMediumAndLarge {
  display: none;
  @include responsive($MEDIUM) {
    display: flex;
  }
  @include responsive($X_LARGE) {
    display: none;
  }
}

.onlyOnXl {
  display: none;
  @include responsive($X_LARGE) {
    display: flex;
  }
}

@mixin flex-column-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

%flex-column-start {
  @include flex-column-start;
}

@mixin flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

%flex-row-center {
  @include flex-row-center;
}

@mixin buttonRegular {
  font-size: var(--font-size-button-regular);
  font-weight: var(--font-weight-button-regular);
  line-height: var(--line-height-button-regular);
  letter-spacing: var(--letter-spacing);
}

@mixin outlinedToggleButton {
  box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: var(--Spacing-48, 48px);
  border-radius: 100px;
  padding: 12px 16px 12px 16px;
  background: transparent;
  color: var(--neutral-700);
  border: solid 1px;
  border-color: var(--neutral-700);
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  background: transparent;
  @include buttonRegular;
  text-decoration: none;
  display: flex;
  align-items: center;
  svg {
    margin-left: 4px;
  }
  &:hover {
    color: var(--text-button-hover);
    border-color: var(--text-button-hover);
    svg {
      fill: var(--text-button-hover);
    }
  }
}

@mixin outlinedToggleButtonActive {
  @include outlinedToggleButton;
  &:hover {
    color: var(--white-primary);
  }
  border: solid 1px var(--purple-primary);
  background: var(--purple-primary);
  font-weight: 600;
}

@mixin outlinedToggleButtonDark {
  @include outlinedToggleButton;
  & {
    color: var(--white-primary);
    border-color: var(--white-primary, #ffffff);

    &:hover {
      border: 1px solid var(--Purple-Primary, #9747ff);
      color: var(--Purple-Primary, #9747ff);
      svg {
        fill: var(--Purple-Primary, #9747ff);
      }
    }
  }
}

@mixin outlinedToggleButtonActiveDark {
  @include outlinedToggleButtonDark;
  & {
    border: solid 1px var(--purple-primary);
    &:hover {
      color: var(--white-primary, #ffffff);
    }
    & {
      background: var(--purple-primary);
      color: var(--white-primary);
      font-weight: 600;
    }
  }
}

@mixin typographySpacing(
  $headerToSubheadMobile,
  $headerToSubhead,
  $headerToBodyMobile,
  $headerToBody,
  $bodyToHeaderMobile,
  $bodyToHeader,
  $bodyToSubheadMobile,
  $bodyToSubhead,
  $largeBodyTolargeBodyMobile,
  $largeBodyTolargeBody,
  $regBodyToRegBodyMobile,
  $regBodyToRegBody,
  $subheadToBodyMobile,
  $subheadToBody
) {
  /* Header/Subhead Separator */
  h1 + h4,
  h1 + h5,
  h1 + h6,
  h2 + h4,
  h2 + h5,
  h2 + h6,
  h3 + h4,
  h3 + h5,
  h3 + h6 {
    margin-top: $headerToSubheadMobile;
    @include responsive($MEDIUM) {
      margin-top: $headerToSubhead;
    }
  }

  /* Header/Body Separator */
  h1 + p,
  h2 + p,
  h3 + p,
  h1 + ul,
  h2 + ul,
  h3 + ul {
    margin-top: $headerToBodyMobile;
    @include responsive($MEDIUM) {
      margin-top: $headerToBody;
    }
  }

  /* Body/Header Separator */
  p + h1,
  p + h2,
  p + h3,
  ul + h1,
  ul + h2,
  ul + h3 {
    margin-top: $bodyToHeaderMobile;
    @include responsive($MEDIUM) {
      margin-top: $bodyToHeader;
    }
  }

  /* Body/Subhead Separator */
  p + h4,
  p + h5,
  p + h6,
  ul + h4,
  ul + h5,
  ul + h6 {
    margin-top: $bodyToSubheadMobile;
    @include responsive($MEDIUM) {
      margin-top: $bodyToSubhead;
    }
  }

  /* Regular Body-to-Regular Body Separator */
  p + p,
  p + ul,
  ul + p,
  ul + ul {
    margin-top: $regBodyToRegBodyMobile;
    @include responsive($MEDIUM) {
      margin-top: $regBodyToRegBody;
    }
  }

  /* Subhead/Body Separator */
  h4 + p,
  h5 + p,
  h6 + p,
  h4 + ul,
  h5 + ul,
  h6 + ul {
    margin-top: $subheadToBodyMobile;
    @include responsive($MEDIUM) {
      margin-top: $subheadToBody;
    }
  }

  form {
    margin-top: 32px;
  }
}

@mixin typographySpacingRegular {
  display: flex;
  flex-direction: column;
  @include typographySpacing(
    // header to subhead
    12px,
    16px,
    // header to body
    16px,
    16px,
    // body to header
    32px,
    32px,
    // body to subhead
    24px,
    24px,
    // large body to large body
    24px,
    24px,
    // reg body to reg body
    12px,
    12px,
    // subhead to body
    12px,
    12px
  );
}

@mixin typographySpacingInsights {
  display: flex;
  flex-direction: column;
  @include typographySpacing(
    // header to subhead
    16px,
    16px,
    // header to body
    12px,
    12px,
    // body to header
    32px,
    48px,
    // body to subhead
    24px,
    24px,
    // large body to large body
    24px,
    24px,
    // reg body to reg body
    12px,
    12px,
    // subhead to body
    12px,
    12px
  );
}

@mixin responsiveSectionSpacing {
  margin-bottom: var(--spacing-section-medium);
  @include responsive($LARGE) {
    margin-bottom: var(--spacing-section-large);
  }
  @include responsive($X_LARGE) {
    margin-bottom: var(--spacing-section-xl);
  }
}

@mixin responsiveSectionSpacingInverse {
  margin-top: calc(-1 * var(--spacing-section-medium));
  @include responsive($LARGE) {
    margin-top: calc(-1 * var(--spacing-section-large));
  }
  @include responsive($X_LARGE) {
    margin-top: calc(-1 * var(--spacing-section-xl));
  }
}

@mixin fullContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: var(--container-width-small);
  padding: 0 var(--side-margin-small, 16px);

  @include responsive($MEDIUM) {
    width: var(--container-width-medium);
    padding: 0;
  }

  @include responsive($LARGE) {
    width: var(--container-width-large);

    padding: 0;
  }

  @include responsive($X_LARGE) {
    width: var(--container-width-xl);
    padding: 0;
  }
}

@mixin singleColumnContainer {
  & {
    width: 361px;
  }

  @include responsive($MEDIUM) {
    width: 639px;
  }
  @include responsive($LARGE) {
    width: 610px;
  }
  @include responsive($X_LARGE) {
    width: 789px;
  }
}
