@use '../../global.style.module' as g;

.formContainer {
  @include g.typographySpacingRegular;
}

.inlineInputContainer {
  width: 100%;
  position: relative;
  @include g.responsive(g.$LARGE) {
    width: var(--field-width);
  }
}

.inlineInputEndAdornment {
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: var(--Radius-full, 999px);
  padding: 8px 16px !important;
  color: black !important;
  svg,
  path {
    fill: var(--text-button-default) !important;
  }
  &:hover:not(:disabled) {
    svg,
    path {
      fill: var(--white-primary) !important;
    }
  }

  &:disabled {
    background: var(--light-button) !important;
  }
}

.successMessage {
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 27px */
  letter-spacing: -0.5px;
  background: var(--light-button, linear-gradient(273deg, #e2e0fd -34.32%, #a8a4f8 126.05%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 12px;
}

.input {
  width: 100%;
  border-radius: var(--Radius-full, 999px);
  background: var(--Neutral-100, #fbfafc);
  color: black !important;
  border: 0;
  height: 70px;
  padding: 0 32px;
  font-size: var(--font-size-body-large);
  font-weight: var(--font-weight-body-large);
  line-height: var(--line-height-body-large);

  &:disabled {
    background: #e7e4eb;
  }

  &::placeholder {
    color: black !important;
  }
}
