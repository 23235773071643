@use '../../global.style.module.scss' as g;

.container {
  width: 100vw;
  position: relative;
  overflow: hidden;
  @include g.responsiveSectionSpacing;
}

.halfImg {
  @include g.responsive(g.$MEDIUM) {
    display: block !important;
    top: 0;
    width: auto;
  }
}

.imgBackground {
  position: absolute;
  width: 100vw;
  height: 100%;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.halfImgBackground {
  @extend .imgBackground;
  @include g.responsive(g.$MEDIUM) {
    position: unset;
    width: unset !important;
    top: unset;
    left: unset !important;
    z-index: unset;
  }
}

.halfContent {
  width: 100%;
  position: relative;
  @include g.responsive(g.$MEDIUM) {
    width: 50%;
    min-width: 50%;
  }
  & {
    z-index: 1;
  }
}

.whiteContent {
  * {
    color: var(--white-primary);
  }
}

.full {
  @include g.fullContainer;
  @extend .whiteContent;
  & {
    text-align: center;
  }
}

.singleColumn {
  @include g.fullContainer;
  @extend .whiteContent;
  @include g.singleColumnContainer;

  h4 {
    width: 100%;
  }
}

.half {
  @include g.fullContainer;
  @extend .whiteContent;
}

.contentContainer {
  position: relative;
  z-index: 1;
  @include g.typographySpacingRegular;

  h4 {
    font-size: var(--font-size-h6);
    font-weight: var(--font-weight-h6);
    line-height: var(--line-height-h6);
    @include g.responsive(g.$LARGE) {
      font-size: var(--font-size-h4);
      font-weight: var(--font-weight-h4);
      line-height: var(--line-height-h4);
    }
  }

  h3 {
    font-size: var(--font-size-h5);
    font-weight: var(--font-weight-h5);
    line-height: var(--line-height-h5);
    @include g.responsive(g.$LARGE) {
      font-size: var(--font-size-h3);
      font-weight: var(--font-weight-h3);
      line-height: var(--line-height-h3);
    }
  }
  p {
    @include g.bodyRegular;
    @include g.responsive(g.$LARGE) {
      @include g.bodyLarge;
    }
  }
}
.cta {
  h3 + p {
    margin-top: 16px;
  }
}
.highlight {
  h4 + p {
    margin-top: 32px;
  }
  p + p {
    margin-top: 12px;
    @include g.responsive(g.$LARGE) {
      margin-top: 24px;
    }
  }
}

.overlay {
  display: none;
  position: absolute;
  bottom: calc(100% - 80px);
  width: auto;

  @include g.responsive(g.$MEDIUM) {
    display: block;
    right: 40px;
    height: 130px;
  }
  @include g.responsive(g.$LARGE) {
    right: 60px;

    height: 176px;
  }
  @include g.responsive(g.$X_LARGE) {
    right: 80px;

    height: 200px;
  }
}

.midCenter {
  text-align: center;
}
