@use '../../global.style.module.scss' as g;

.bulletList {
  display: flex;
  flex-direction: column;
  position: relative;

  // gap: var(--spacing-16);

  li + li {
    margin-top: 12px;
  }

  li {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  li p {
    margin-left: 1rem;
  }
}

.bulletPoint {
  position: absolute;
  width: 7px;
  height: 7px;
  background-color: var(--black-primary);
  border-radius: 50%;
  margin: 0;
  padding: 0;
}

.ctaButton {
  color: var(--text-button-default) !important;
  margin-top: 32px;

  svg {
    fill: var(--text-button-default) !important;
  }
  &:hover {
    color: var(--white-primary) !important;
    svg {
      fill: var(--white-primary) !important;
    }
  }
  @include g.responsive(g.$LARGE) {
    margin-top: 48px;
  }
}
