@use '../../global.style.module' as g;

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0;
  @include g.responsiveSectionSpacing;

  @include g.responsive(g.$MEDIUM) {
    gap: var(--spacing-section-small);
  }

  @include g.responsive(g.$LARGE) {
    gap: var(--side-margin-medium);
  }
}

.gridCardContainer {
  flex-direction: row;
  flex-wrap: nowrap;
  gap: var(--spacing-section-small);
  display: none;

  @include g.responsive(g.$MEDIUM) {
    display: flex;
    flex-direction: column;
  }

  @include g.responsive(g.$LARGE) {
    display: grid;
    grid-auto-rows: 1fr;

    &.hide {
      display: none;
    }
  }
}

.gridCardMobileContainer {
  width: 100%;

  @include g.responsive(g.$MEDIUM) {
    display: none;
  }
}

.gridCardRow {
  width: 100%;
  display: flex;
  align-items: stretch;
  gap: var(--spacing-section-small);

  &::-webkit-scrollbar {
    display: none;
  }

  @include g.responsive(g.$SMALL) {
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }

  @include g.responsive(g.$MEDIUM) {
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
  }

  @include g.responsive(g.$LARGE) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.gridCard {
  min-height: 500px;
  display: flex;
  gap: 24px;
  padding: 24px;
  border-radius: 48px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow:
    4px 4px 20px 0 rgba(143, 170, 255, 0.1),
    4px 4px 36px 0 rgba(0, 0, 0, 0.05);

  @include g.responsive(g.$SMALL) {
    width: 280px;
    min-width: 280px;
    flex-direction: column;
  }

  @include g.responsive(g.$MEDIUM) {
    min-width: auto;
    width: 100%;
    flex-direction: row;
  }

  @include g.responsive(g.$LARGE) {
    flex-direction: column;
    &.narrow {
      width: calc(calc(100% - 64px) / 3);
    }

    &.standard {
      width: calc(calc(100% - 32px) / 2);
    }
  }
}

.gridCardImageContainer {
  position: relative;
  width: 80px;
  height: 80px;
}

.gridCardImage {
  position: absolute;
  top: 0;
  opacity: 0;
}

.gridCardMobile {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow:
    4px 4px 20px 0 rgba(143, 170, 255, 0.1),
    4px 4px 36px 0 rgba(0, 0, 0, 0.05);
}

.filterGridContainer {
  display: none;
  flex-wrap: nowrap;
  width: 100%;

  &.hide {
    display: none !important;
  }

  @include g.responsive(g.$LARGE) {
    display: flex;
    gap: 32px;
  }
}

.filterChipSquareContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: calc(100% - 500px);
}

.filterChipWideContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: calc(100% - 790px);
}

.filterSquareContent {
  min-width: 500px;
  width: 500px;
  height: fit-content;
}

.filterWideContent {
  min-width: 790px;
  width: 790px;
  height: fit-content;
}

.filterChipRow {
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
  width: 100%;
}

.filterChip {
  @include g.h6;
  display: block;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  color: #1a0e2f;
  padding: 12px 24px;
  border-radius: var(--spacing-48, 48px);
  border: 1px solid var(--text-body, #1a0e2f);
  box-shadow: 4px 4px 40px 0 rgba(0, 0, 0, 0.05);
  font-size: var(--font-size-body-regular);
  font-weight: var(--font-weight-body-regular);
  line-height: var(--line-height-body-regular);
  letter-spacing: var(--letter-spacing);
  cursor: pointer;
  height: 51px;
  background: transparent;

  &:hover {
    background: transparent;
    color: var(--purple-primary, #9747ff);
    border: 1px solid var(--purple-primary, #9747ff);
  }
}

.activeFilterChip {
  border: 1px solid var(--purple-primary, #9747ff) !important;
  color: var(--neutral-100) !important;
  background: var(--purple-primary, #9747ff) !important;
}

.gridCardTextContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.containerTitle {
  width: 100%;

  @include g.responsive(g.$MEDIUM) {
    text-align: start;
  }

  @include g.responsive(g.$LARGE) {
    text-align: start;
  }
}

.cardTitle {
  @include g.h6;
  color: var(--neutral-900) !important;

  @include g.responsive(g.$MEDIUM) {
    @include g.h4;
  }
}

.cardBody {
  @include g.bodyRegular;
  color: var(--neutral-900);
  //overflow: hidden;
  //display: -webkit-box;
  //line-clamp: 5;
  //-webkit-line-clamp: 5;
  //-webkit-box-orient: vertical;
}

.cardBodyLarge {
  @include g.bodyLarge;
  color: var(--neutral-900);
  //overflow: hidden;
  //display: -webkit-box;
  //line-clamp: 5;
  //-webkit-line-clamp: 5;
  //-webkit-box-orient: vertical;
}
