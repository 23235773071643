@use '../../global.style.module' as g;

.container {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-section-small) var(--side-margin-small);
  width: 100vw;
  position: relative;
  gap: 32px;
  @include g.responsiveSectionSpacing;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li {
    color: var(--white-primary) !important;
  }

  h3 {
    @include g.h3;
  }

  li {
    list-style-type: none;
    position: relative;
    padding-left: 26.5px;
    break-inside: avoid;
  }

  li::before {
    content: '\2022';
    font-size: 18px;
    line-height: 20px;
    position: absolute;
    top: 3px;
    left: 0;
    width: 26.5px;
    text-indent: 8.5px;
  }

  li + li {
    margin-top: 24px;
  }

  a {
    color: #c9aaff;
    text-decoration: none;
    &:hover {
      color: var(--text-button-hover);
    }
  }

  @include g.responsive(g.$MEDIUM) {
    padding: var(--spacing-section-medium) calc(0.5 * calc(100vw - var(--container-width-medium)));
  }

  @include g.responsive(g.$LARGE) {
    padding: var(--spacing-section-medium) calc(0.5 * calc(100vw - var(--container-width-large)));

    ul {
      column-count: 2;
      column-gap: 32px;
    }
  }

  @include g.responsive(g.$X_LARGE) {
    padding: var(--spacing-section-medium) calc(0.5 * calc(100vw - var(--container-width-xl)));
  }
}

.originalSpacing {
  gap: 32px;
  li + li {
    margin-top: 27px;
  }
}
